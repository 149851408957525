var Utils = Backbone.View.extend({

    initialize:function(){
        var self = this;

        this.election = {};
        this.sliders = {};
        this.translations = {};

        this.couleurspol = {
            c:"#f3a33d",
            d:"#3a789f",
            ed:"#252738",
            eg:"#670000",
            g:"#b61818",
            autres:"#d7caca",
            v:"#76a31f",
            Autres:"#d7caca",
            Conservative:"#118cd1",
            Labour:"#b4201b",
            Liberal:"#de841b",
            SNP:"#ffd900",
            UKIP:"#854997"
        };

    },

    gatherData:function(callback){
        var self = this;
        this.gatherElections(function(){
            self.gatherSliders(function(){
                self.gatherTranslations(function(){
                    return callback();
                });
            });
        });
    },

    gatherUKData:function(callback){
        var self = this;
        this.gatherUKElections(function(){
            self.gatherSliders(function(){
                self.gatherTranslations(function(){
                    return callback();
                });
            });
        });
    },

    gatherTranslations:function(callback){
        var self = this;
        d3.csv("data/translations.csv", function(data,i){
            self.translations[data.key] = {
                key:data.key,
                fr:data.fr,
                en:data.en
            };
        }, function(error, rows) {
            //console.log(error, rows);
            return callback();
        });
    },

    gatherElections:function(callback){
        var self = this;
        d3.csv("data/elections.csv", function(data,i){
            self.election[i] = {
                election:data.election,
                annee:data.annee,
                circomairie:data.circomairie,
                candidat:data.candidat,
                departement:data.departement,
                codedepartement:data.codedepartement,
                parti:data.parti,
                nuance:data.nuance_mInterieur,
                couleur:data.couleur,
                voix:data.pourcentage_voix,
                depenses:data.pourcentage_depenses

            };
        }, function(error, rows) {
            //console.log(error, rows);
            return callback();
        });

    },

    gatherUKElections:function(callback){
        var self = this;
        d3.csv("data/elections-uk.csv", function(data,i){
            self.election[i] = {
                election:data.election,
                annee:data.annee,
                circomairie:data.circomairie,
                candidat:data.candidat,
                departement:data.departement,
                codedepartement:data.codedepartement,
                parti:data.parti,
                nuance:data.nuance_mInterieur,
                couleur:data.couleur,
                voix:data.pourcentage_voix,
                depenses:data.pourcentage_depenses

            };
        }, function(error, rows) {
            //console.log(error, rows);
            return callback();
        });

    },

    gatherSliders:function(callback){
        var self = this;
        console.log(App.selectedLang)
        if(App.selectedLang==="fr"){
            d3.csv("data/sliders.csv", function(data,i){
                self.sliders[data.slider+data.id] = {
                    slider:data.slider,
                    id:parseInt(data.id),
                    txt:data.txt,
                    ptext:data.ptext,
                    img:data.img
                    
                };
            }, function(error, rows) {
                //console.log(error, rows);
                return callback();
            });
        }else{
            d3.csv("data/sliders.csv", function(data,i){
                self.sliders[data.slider+data.id] = {
                    slider:data.slider,
                    id:parseInt(data.id),
                    txt:data.txten,
                    ptext:data.ptext,
                    img:data.imgen
                    
                };
            }, function(error, rows) {
                //console.log(error, rows);
                return callback();
            });
        }

    },

    fillTranslations:function(){
        var self = this;

        _.each($(".transblock"), function(ele){
            var $ele = $(ele);
            var dataTrans = $ele.attr("data-trans");
            $ele.html(self.translations[dataTrans][App.selectedLang]);
        });

        $(".head_langbt[data-lang='"+App.selectedLang+"']").addClass("selectedLang");
    },

});

module.exports = Utils;;