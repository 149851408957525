var LivreView = Backbone.View.extend({

    initialize:function(){
        var self = this;
    },

    render:function(){
        this.bindEvents();
        if(App.selectedLang==="fr"){
        	$(".livre_graph_img[data-lang='en']").css("display","none");
            $(".livre_main_img[data-lang='en']").css("display","none");
        }else{
        	$(".livre_graph_img[data-lang='fr']").css("display","none");
            $(".livre_main_img[data-lang='fr']").css("display","none");
        }

    },


    bindEvents:function(){
        var self = this;
    },
});

module.exports = LivreView;