var DataView = Backbone.View.extend({

    initialize:function(){
        var self = this;
		if($("body").attr("data-type")==="france"){
	        App.utils.gatherData(function(){
	            self.render();
	        });
        }else if($("body").attr("data-type")==="uk"){
	        App.utils.gatherUKData(function(){
	            self.render();
	        });
		}
	},

    render:function(){
        this.bindEvents();
        if(window.innerWidth>639){
        	this.buildChart();
    	}
    	
		
    },

    buildChart:function(){
		var datas = [];

		if($("body").attr("data-type")==="france"){
			var paysElection = "legislatives";
			var paysAnnee = "2012";
		}else{
			var paysElection = "General Elections";
			var paysAnnee = "2017";
		}

		if(App.selectedLang==="fr"){
    		var legDepenses = "part des dépenses";
    		var legVoix = "part des voix";
    		var percentDepenses = "% des dépenses<br>";
    		var percentVoix = "% des voix";
        }else{
        	var legDepenses = "share of spendings";
    		var legVoix = "share of votes";
    		var percentDepenses = "% of spendings<br>";
    		var percentVoix = "% of votes";
        }

		_.each(App.utils.election,function(data){
    		if(data.election==paysElection&&data.annee==paysAnnee){
    			if(data.depenses!=""&&data.voix!=""&&typeof data.depenses!="undefined"&&typeof data.voix!="undefined"){
	    			var point = {x:parseFloat(parseFloat(data.depenses.replace(",",".")).toFixed(1)),y:parseFloat(parseFloat(data.voix.replace(",",".")).toFixed(1)),name:data.candidat,color:App.utils.couleurspol[data.couleur],parti:data.parti,couleurpol:data.couleur,circomairie:data.circomairie,departement:data.departement,election:data.election}
					datas.push(point)
    			}
    		}
    	});

    	this.myChart = new Highcharts.chart({
		    chart: {
		        type: 'scatter',
		        zoomType: 'xy',
		        renderTo:'plot_container',
		        events:{
		        	redraw:function(e){
		        		setTimeout(function(){
		        			App.dataView.removeLoader();
		        		},500)
		        	}
		        }
		    },
		    title: {
		        text: ''
		    },
		    subtitle: {
		        text: ''
		    },
		    credits:{
		    	enabled:false
		    },
		    xAxis: {
		        title: {
		            enabled: true,
		            text: legDepenses,
		        },
		        min:0
		    },
		    yAxis: {
		        title: {
		            text: legVoix
		        }
		    },
		    legend:{
		    	enabled:false
		    },
		    tooltip: {
				useHTML:true,
				enabled:true,
				formatter:function(){
					var c = _.str.slugify(this.key);
					var pstyle = $(".highcharts-series-0 path[data-candidat='"+c+"']").attr("style");
					var pfill = $(".highcharts-series-0 path[data-candidat='"+c+"']").attr("data-masked");

					if(pstyle == "display:none"||pfill== "masked"){
						return false;
					}else{
						var d = this.x.toString();
						var v = this.y.toString();
						return(this.key.toUpperCase()+"<br>"+d.replace(".",",")+percentDepenses+v.replace(".",",")+percentVoix);
					}

					/*if(this.point.graphic.element.style.cssText=="display: none;"){
						return false;
					}else{
						return(this.key.toUpperCase()+"<br>"+this.x+"% des dépenses<br>"+this.y+"% des voix<br>");
					}*/
				}
			},
		    plotOptions: {
		        scatter: {
		            marker: {
		                radius: 2,
		                states: {
		                    hover: {
		                        enabled: false,
		                        lineColor: 'rgb(100,100,100)'
		                    }
		                }
		            },
		            states: {
		                hover: {
		                    marker: {
		                        enabled: false
		                    }
		                }
		            }
		        }
		    },
		    series: [{
		    	turboThreshold:10000,
		    	data:datas,
		    	states: { hover: { enabled: false } } 
		    }]
		});

    	var paths = $(".highcharts-series-0 path");
		_.each(paths,function(p,i){
    		$(p).attr("data-candidat",_.str.slugify(datas[i].name));
    		$(p).attr("data-couleur",datas[i].couleurpol);
    		if(datas[i].election=="legislatives"){
    			$(p).attr("data-circomairie",_.str.slugify(datas[i].departement+datas[i].circomairie));
    		}else{
    			$(p).attr("data-circomairie",_.str.slugify(datas[i].circomairie));
    		}
    	});
		this.buildAutocomplete(paysElection,paysAnnee);
		this.udpateTxtHighlight(paysElection,paysAnnee);
	},

    updateData:function(selectedAnnee){
    	
    	if($("body").attr("data-type")==="france"){
    		var selectedElection = $("#sidebar").attr("data-election");
		}else{
			var selectedElection = "General Elections";
		}
 		var newData = [];
		_.each(App.utils.election,function(data){
    		if(data.election==selectedElection&&data.annee==selectedAnnee){
    			if(data.depenses!=""&&data.voix!=""&&typeof data.depenses!="undefined"&&typeof data.voix!="undefined"){
	    			var point = {x:parseFloat(parseFloat(data.depenses.replace(",",".")).toFixed(1)),y:parseFloat(parseFloat(data.voix.replace(",",".")).toFixed(1)),name:data.candidat,color:App.utils.couleurspol[data.couleur],parti:data.parti,couleurpol:data.couleur,circomairie:data.circomairie,departement:data.departement,election:data.election}
					newData.push(point)
    			}
    		}
    	});

		this.myChart.series[0].update({
    		data:newData,
    	});

		var paths = $(".highcharts-series-0 path");
		_.each(paths,function(p,i){
    		$(p).attr("data-candidat",_.str.slugify(newData[i].name));
    		$(p).attr("data-couleur",newData[i].couleurpol);
    		if(newData[i].election=="legislatives"){
    			$(p).attr("data-circomairie",_.str.slugify(newData[i].departement+newData[i].circomairie));
    		}else{
    			$(p).attr("data-circomairie",_.str.slugify(newData[i].circomairie));
    		}
    	});


    	this.cleanAllFilter();
    	this.buildAutocomplete(selectedElection,selectedAnnee);
    	this.udpateTxtHighlight(selectedElection,selectedAnnee);
    	
    },

    udpateGraphColor:function(selectedColor){
		var self = this;
    	var paths = $(".highcharts-series-0 path");
	    _.each(paths,function(p,i){
	    	var couleur = $(p).attr("data-couleur");
	    	if(selectedColor.includes(couleur)){
	    		$(p).attr("fill",App.utils.couleurspol[couleur]);
	    		$(p).attr("data-masked",'unmasked');
	    	}else{
	    		$(p).attr("data-masked",'masked');
	    	}
	    });
	    self.updateFiltredHigh();
    },

	bindEvents:function(){
        var self = this;

        $("#election_toggle_box").on("click",function(){
			$("#election_toggle_btn").toggleClass("change");
			if($("#sidebar").attr("data-election") == "legislatives"){
				
				self.addLoader();
				
				setTimeout(function(){
					$("#sidebar").attr("data-election","municipales");
					var selectedAnnee = $(".annee_selector_container[data-annes='municipales'] .annees_selector_item.annee_active").attr("data-annee");
					self.updateData(selectedAnnee);
				},10)
			}else{

				self.addLoader();
				
				setTimeout(function(){
					$("#sidebar").attr("data-election","legislatives");
					var selectedAnnee = $(".annee_selector_container[data-annes='legislatives'] .annees_selector_item.annee_active").attr("data-annee");
					self.updateData(selectedAnnee);
				},10)
			}
			
		});

		$(".annees_selector_item").on("click",function(){
			var parent = $(this).parent(".annee_selector_container");
			parent.find($(".annees_selector_item")).removeClass("annee_active");
			$(this).addClass("annee_active");
			self.addLoader();
			annee = $(this).attr("data-annee")
			setTimeout(function(){
				self.updateData(annee);
			},10)
		});

		$(".legende_box").on("click",function(){

			if(($(".sidebar_box[data-box='legende']").hasClass("allSelect"))){
				$(".legende_box_tick").removeClass("appear");
				$(".sidebar_box[data-box='legende']").removeClass("allSelect");
			}

			$(this).find(".legende_box_tick").toggleClass("appear");

			var tabCouleur = []

			setTimeout(function(){

			 	_.each($(".legende_box"),function(e){
			 		if(($(e).find(".legende_box_tick").hasClass("appear"))){
			 			tabCouleur.push(($(e).attr("data-parti")));
			 		}
			 	})

			 	self.udpateGraphColor(tabCouleur);

			 	if(tabCouleur.length==$(".legende_box").length){
			 		$(".sidebar_box[data-box='legende']").addClass("allSelect");
			 		$(".highlight_data_content").removeClass("loading");
			 	}
			 	

			},10);
			
		});

		$(".search_input").on("focus",function(){
			self.cleanAllFilter();
		});

		$(".input_erase").on("click",function(){
			self.cleanAllFilter();
		});

		$(".highlight_candidat_name").on("click",function(){
			$("#candidat_input").val(_.str.prune($(this).attr("data-realname"),25));
			self.highlightCandidat($(this).attr("data-candidat"));
		});


    	$('.innerlink').on('click', function(e){
       		e.preventDefault(); 
       		var target = $(this).attr('href');
       		$('html, body').stop().animate({scrollTop: $(target).offset().top-100}, 1000 );
       		App.anchor = target;
       		App.router.updateRoute();
    	});

    	$(".year_roll_y").on("click",function(){

    		var annee = $(this).attr("data-y");
    		$("#year_roll").removeClass("open");
    		$("#year_roll").scrollTop(0);
    		$(".year_roll_y").css("display","block");
    		$(this).css("display","none")
			$("#year_roll_selected").html(annee)

    		setTimeout(function(){
				self.updateData(annee);
			},10)

    	});

    	$("#year_roll_selected").on("click",function(){
    		$("#year_roll").addClass("open");
    	})

	},

	buildAutocomplete:function(selectedElection,selectedAnnee){
		var self = this;
		var tabCandidats = [];
		_.each(App.utils.election,function(e){
			if(e.election==selectedElection&&e.annee==selectedAnnee){
        		tabCandidats.push({value:_.str.slugify(e.candidat), label:e.candidat.toUpperCase()});
        	}
		});
		self.buildCandidatsInput(tabCandidats);
		self.buildCircoInput();
		self.buildMairieInput();
	},

	addLoader:function(){

		$("#plot_loader_container").addClass("loading");
		$(".highlight_data_content").addClass("loading");
		$(".loader").addClass("loading");

	},

	removeLoader:function(){

		$("#plot_loader_container").removeClass("loading");
		$(".highlight_data_content").removeClass("loading");
		$(".loader").removeClass("loading");

	},

	buildCandidatsInput:function(tabCandidats){
		var self = this;
        $("#candidat_input").autocomplete({
            source: tabCandidats,
            minLength: 3,
            select:function(event,ui){
            	$("#candidat_input").val(_.str.prune(ui.item.label,25));
            	self.highlightCandidat(ui.item.value);
                return false;
            },
            open:function(){
            },
            close:function(){
            },
            focus: function (event, ui) {
                this.value = ui.item.label;
            },
            source: function(request, response) {
                var results = $.ui.autocomplete.filter(tabCandidats, request.term);        
                response(results.slice(0, 20));
            }
        }).autocomplete( "instance" )._renderItem = function( ul, item ) {
            var re = new RegExp( "(" + this.term + ")", "gi" ),
                cls = "autocomplete-h",
                template = "<span class='" + cls + "'>$1</span>",
                label = item.label.replace( re, template ),
                $li = $( "<li/>" ).appendTo( ul );

            $( "<div/>" ).html( label ).appendTo( $li );
            return $li;
        };
	},

	buildCircoInput:function(){
		var self = this;
		tabCircoString = [];
		objCirco = [];
		_.each(App.utils.election,function(e){
			if(e.election=="legislatives"||e.election==="General Elections"){
				var circoString = e.departement.toUpperCase()+" - "+e.circomairie;
				if(tabCircoString.includes(circoString)){
				}else{
					tabCircoString.push(circoString);
					if(e.election=="legislatives"){
						objCirco.push({value:_.str.slugify(e.departement+e.circomairie), label:e.circomairie.toUpperCase()})
					}else{
						objCirco.push({value:_.str.slugify(e.circomairie), label:e.circomairie.toUpperCase()})
					}
				}
			}
		});

		$("#circo_input").autocomplete({
            source: objCirco,
            minLength: 3,
            select:function(event,ui){
            	$("#circo_input").val(_.str.prune(ui.item.label,25));
            	self.highlightCirco(ui.item.value);
                return false;
            },
            open:function(){
            },
            close:function(){
            },
            focus: function (event, ui) {
                this.value = ui.item.label;
            },
            source: function(request, response) {
                var results = $.ui.autocomplete.filter(objCirco, request.term);        
                response(results.slice(0, 20));
            }
        }).autocomplete( "instance" )._renderItem = function( ul, item ) {
            var re = new RegExp( "(" + this.term + ")", "gi" ),
                cls = "autocomplete-h",
                template = "<span class='" + cls + "'>$1</span>",
                label = item.label.replace( re, template ),
                $li = $( "<li/>" ).appendTo( ul );

            $( "<div/>" ).html( label ).appendTo( $li );
            return $li;
        };
	},

	buildMairieInput:function(){
		var self = this;
		tabMairieString = [];
		objMairie = [];

		_.each(App.utils.election,function(e){
			if(e.election=="municipales"){
				var mairieString = _.str.slugify(e.circomairie);
				if(tabMairieString.includes(mairieString)){

				}else{
					tabMairieString.push(mairieString);
					objMairie.push({value:_.str.slugify(e.circomairie), label:e.circomairie.toUpperCase()})
				}
			}
		});

		$("#mairie_input").autocomplete({
            source: objMairie,
            minLength: 3,
            select:function(event,ui){
            	$("#mairie_input").val(_.str.prune(ui.item.label,25));
            	self.highlightMairie(ui.item.value);
                return false;
            },
            open:function(){
            },
            close:function(){
            },
            focus: function (event, ui) {
                this.value = ui.item.label;
            },
            source: function(request, response) {
                var results = $.ui.autocomplete.filter(objMairie, request.term);        
                response(results.slice(0, 20));
            }
        }).autocomplete( "instance" )._renderItem = function( ul, item ) {
            var re = new RegExp( "(" + this.term + ")", "gi" ),
                cls = "autocomplete-h",
                template = "<span class='" + cls + "'>$1</span>",
                label = item.label.replace( re, template ),
                $li = $( "<li/>" ).appendTo( ul );

            $( "<div/>" ).html( label ).appendTo( $li );
            return $li;
        };
	},

	highlightCandidat:function(candidat){
		var self = this;
		self.resetColorFilter();
		$("#candidat_input + .input_erase").addClass("appear");
		var paths = $(".highcharts-series-0 path");
		var circomairie;
		_.each(paths,function(p1,i){
			if($(p1).attr("data-candidat")==candidat){
				circomairie = $(p1).attr("data-circomairie");
			}
		});

		$(".highlight_data_content").addClass("loading");
		$(".highlight_data_case").addClass("loading");

		_.each(paths,function(p,i){
			var circoPoint = $(p).attr("data-circomairie");
			var couleur = $(p).attr("data-couleur");
			if(circoPoint==circomairie){
				if($(p).attr("data-candidat")==candidat){
					_.each(App.dataView.myChart.series[0].points,function(p){
						if(_.str.slugify(p.name) == candidat){
							App.dataView.selectedPoint = p;
							//p.graphic.element.style.cssText = "display: block;"
							p.setState("hover");
							App.dataView.myChart.tooltip.refresh(App.dataView.selectedPoint);
						}
					});
					$(p).attr("fill",App.utils.couleurspol[couleur])
					$(p).attr("data-masked","unmasked")
				}else{
					$(p).attr("data-opponent","opponent")
					$(p).attr("style","display:block")
				}
	    	}else{
				$(p).attr("data-masked","masked")
	    	}
		});
		self.updateFiltredHigh();
	},

	highlightCirco:function(circo){
		var self = this;
		self.resetColorFilter();
		console.log(circo)
		$("#circo_input + .input_erase").addClass("appear");
		var paths = $(".highcharts-series-0 path");
		_.each(paths,function(p,i){
			var circoPoint = $(p).attr("data-circomairie");
			var couleur = $(p).attr("data-couleur");
			if(circoPoint==circo){
				$(p).attr("fill",App.utils.couleurspol[couleur])
	    	}else{
				$(p).attr("data-masked","masked")
	    	}
	    });
	    self.updateFiltredHigh(circo);
	    
	},

	highlightMairie:function(mairie){
		var self = this;
		self.resetColorFilter();
		$("#mairie_input + .input_erase").addClass("appear");
		var paths = $(".highcharts-series-0 path");
		_.each(paths,function(p,i){
			var mairiePoint = $(p).attr("data-circomairie");
			var couleur = $(p).attr("data-couleur");
			if(mairiePoint==mairie){
				$(p).attr("fill",App.utils.couleurspol[couleur])
	    	}else{
				$(p).attr("style","display:none")
	    	}
	    });
		
	},

	cleanAllFilter:function(){
		var self = this;
		var paths = $(".highcharts-series-0 path");
		_.each(paths,function(p,i){
			var couleur = $(p).attr("data-couleur");
			$(p).attr("fill",App.utils.couleurspol[couleur])
	    	$(p).attr("style","")
	    	$(p).attr("data-masked","unmasked")
	    });		
		$(".legende_box_tick").addClass("appear");
	    $(".search_input").val("");
	    $(".input_erase").removeClass("appear");
	   	$(".highlight_data_case").removeClass("loading");
	    $(".highlight_data_content").removeClass("loading");
	},

	resetColorFilter:function(){
		var self = this;

	},

	udpateTxtHighlight:function(sElections,sAnnee){
		var self = this;

		$(".highlight_data_content").removeClass("loading");
    	$(".highlight_data_case").removeClass("loading");
		
		selectCandidat = [];

		_.each(App.utils.election,function(e){
			if(e.election==sElections&&e.annee==sAnnee){
				if(e.depenses!=""&&e.voix!=""&&typeof e.depenses!="undefined"&&typeof e.voix!="undefined"&&e.depenses!="0"&&e.voix!="0"){
					var ratio = parseFloat(e.depenses.replace(",","."))/parseFloat(e.voix.replace(",","."));
					if(parseFloat(e.depenses.replace(",","."))>1&&parseFloat(e.voix.replace(",","."))>1){
						selectCandidat.push({candidat:e.candidat,depenses:parseFloat(e.depenses.replace(",",".")),voix:parseFloat(e.voix.replace(",",".")),ratio:ratio});
					}
				}
			}
		});

		var sortedCandidatDepenses = _.sortBy(selectCandidat,function(o){
			return o.depenses;
		});

		var depenseCandidatObj = sortedCandidatDepenses[sortedCandidatDepenses.length-1];

		var sortedCandidatRatio = _.sortBy(selectCandidat,function(o){
			return o.ratio;
		});

		var failCandidatObj = sortedCandidatRatio[sortedCandidatRatio.length-1];
		var winCandidatObj = sortedCandidatRatio[0];

		$(".highlight_box[data-highlight='depense'] .highlight_candidat_name").html(_.str.prune(depenseCandidatObj.candidat.toUpperCase(),20)).attr("data-candidat",_.str.slugify(depenseCandidatObj.candidat)).attr("data-realname",depenseCandidatObj.candidat.toUpperCase());
		$(".highlight_box[data-highlight='depense'] .highlight_data_box[data-value='argent'] .highlight_data_txt").html(parseInt(depenseCandidatObj.depenses)+"%");
		$(".highlight_box[data-highlight='depense'] .highlight_data_box[data-value='voix'] .highlight_data_txt").html(parseInt(depenseCandidatObj.voix)+"%");

		$(".highlight_box[data-highlight='econome'] .highlight_candidat_name").html(_.str.prune(winCandidatObj.candidat.toUpperCase(),20)).attr("data-candidat",_.str.slugify(winCandidatObj.candidat)).attr("data-realname",winCandidatObj.candidat.toUpperCase());
		$(".highlight_box[data-highlight='econome'] .highlight_data_box[data-value='argent'] .highlight_data_txt").html(parseInt(winCandidatObj.depenses)+"%");
		$(".highlight_box[data-highlight='econome'] .highlight_data_box[data-value='voix'] .highlight_data_txt").html(parseInt(winCandidatObj.voix)+"%");

		$(".highlight_box[data-highlight='fail'] .highlight_candidat_name").html(_.str.prune(failCandidatObj.candidat.toUpperCase(),20)).attr("data-candidat",_.str.slugify(failCandidatObj.candidat)).attr("data-realname",failCandidatObj.candidat.toUpperCase());
		$(".highlight_box[data-highlight='fail'] .highlight_data_box[data-value='argent'] .highlight_data_txt").html(parseInt(failCandidatObj.depenses)+"%");
		$(".highlight_box[data-highlight='fail'] .highlight_data_box[data-value='voix'] .highlight_data_txt").html(parseInt(failCandidatObj.voix)+"%");


	},

	updateFiltredHigh:function(circo){

		var self = this;

		$(".highlight_data_content").removeClass("loading");
    	$(".highlight_data_case").removeClass("loading");

		var paths = $(".highcharts-series-0 path");
		var ptab = [];

		var annee = $(".annee_active").attr("data-annee");
		var election = $("#sidebar").attr("data-election");

		_.each(paths,function(p,i){
			if($(p).attr("data-masked")!="masked"){
				ptab.push($(p)[0].dataset.candidat);
			}
		});

		console.log(ptab.length);

		if(ptab.length!=0&&ptab.length>3){
			console.log("filred");
			selectCandidat = [];

			_.each(App.utils.election,function(e){
				if(e.election==election&&e.annee==annee){
					if(ptab.includes(_.str.slugify(e.candidat))){
						if(typeof circo != "undefined"){
							if(_.str.slugify(e.departement+e.circomairie) == circo){
								if(e.depenses!=""&&e.voix!=""&&typeof e.depenses!="undefined"&&typeof e.voix!="undefined"&&e.depenses!="0"&&e.voix!="0"){
									var ratio = parseFloat(e.depenses.replace(",","."))/parseFloat(e.voix.replace(",","."));
									if(parseFloat(e.depenses.replace(",","."))>1&&parseFloat(e.voix.replace(",","."))>1){
										selectCandidat.push({candidat:e.candidat,depenses:parseFloat(e.depenses.replace(",",".")),voix:parseFloat(e.voix.replace(",",".")),ratio:ratio});
									}
								}
							}							
						}else{
							if(e.depenses!=""&&e.voix!=""&&typeof e.depenses!="undefined"&&typeof e.voix!="undefined"&&e.depenses!="0"&&e.voix!="0"){
								var ratio = parseFloat(e.depenses.replace(",","."))/parseFloat(e.voix.replace(",","."));
								if(parseFloat(e.depenses.replace(",","."))>1&&parseFloat(e.voix.replace(",","."))>1){
									selectCandidat.push({candidat:e.candidat,depenses:parseFloat(e.depenses.replace(",",".")),voix:parseFloat(e.voix.replace(",",".")),ratio:ratio});
								}
							}	
						}
						
					}
				}
			});

			var sortedCandidatDepenses = _.sortBy(selectCandidat,function(o){
				return o.depenses;
			});

			var depenseCandidatObj = sortedCandidatDepenses[sortedCandidatDepenses.length-1];

			var sortedCandidatRatio = _.sortBy(selectCandidat,function(o){
				return o.ratio;
			});

			var failCandidatObj = sortedCandidatRatio[sortedCandidatRatio.length-1];
			var winCandidatObj = sortedCandidatRatio[0];

			$(".highlight_box[data-highlight='depense'] .highlight_candidat_name").html(depenseCandidatObj.candidat.toUpperCase()).attr("data-candidat",_.str.slugify(depenseCandidatObj.candidat)).attr("data-realname",depenseCandidatObj.candidat.toUpperCase());
			$(".highlight_box[data-highlight='depense'] .highlight_data_box[data-value='argent'] .highlight_data_txt").html(parseInt(depenseCandidatObj.depenses)+"%");
			$(".highlight_box[data-highlight='depense'] .highlight_data_box[data-value='voix'] .highlight_data_txt").html(parseInt(depenseCandidatObj.voix)+"%");

			$(".highlight_box[data-highlight='econome'] .highlight_candidat_name").html(winCandidatObj.candidat.toUpperCase()).attr("data-candidat",_.str.slugify(winCandidatObj.candidat)).attr("data-realname",winCandidatObj.candidat.toUpperCase());
			$(".highlight_box[data-highlight='econome'] .highlight_data_box[data-value='argent'] .highlight_data_txt").html(parseInt(winCandidatObj.depenses)+"%");
			$(".highlight_box[data-highlight='econome'] .highlight_data_box[data-value='voix'] .highlight_data_txt").html(parseInt(winCandidatObj.voix)+"%");

			$(".highlight_box[data-highlight='fail'] .highlight_candidat_name").html(failCandidatObj.candidat.toUpperCase()).attr("data-candidat",_.str.slugify(failCandidatObj.candidat)).attr("data-realname",failCandidatObj.candidat.toUpperCase());
			$(".highlight_box[data-highlight='fail'] .highlight_data_box[data-value='argent'] .highlight_data_txt").html(parseInt(failCandidatObj.depenses)+"%");
			$(".highlight_box[data-highlight='fail'] .highlight_data_box[data-value='voix'] .highlight_data_txt").html(parseInt(failCandidatObj.voix)+"%");

		}else{
			console.log("non filtred")
			$(".highlight_data_content").addClass("loading");
    		$(".highlight_data_case").addClass("loading");

		}

	}


});

module.exports = DataView;