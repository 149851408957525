var SlidersView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.maxFinancement = 0;
		this.maxEurope = 0;
		if($("body").attr("data-page")=="data"){
	        App.utils.gatherData(function(){
	            self.render();
	        });
        }
	},

    render:function(){
        this.bindEvents();
        this.buildSliders();
        if(App.selectedLang==="fr"){
    		$(".annexes_dl[data-lang='en']").css("display", "none");
        }else{
        	$(".annexes_dl[data-lang='fr']").css("display", "none");
        }
    },


    bindEvents:function(){
        var self = this;

        $(".next_clic_zone").on("click",function(){
        	var parent = $(this).closest(".graph_wrapper").attr("data-graph");
        	if($(this).hasClass("reset")){
        		self.jumpToSlide(parent,0);
        	}else{
        		self.nextSlide(parent);
        	}
		});	

        $(".prev_clic_zone").on("click",function(){
        	var parent = $(this).closest(".graph_wrapper").attr("data-graph");
        	self.prevSlide(parent);
		});	

        $(".homeslide_btn").on("click",function(){
        	var parent = $(this).closest(".graph_wrapper").attr("data-graph");
        	self.nextSlide(parent);
		});	

		 $(".endslide_btn").on("click",function(){
        	var parent = $(this).closest(".graph_wrapper").attr("data-graph");
        	self.jumpToSlide(parent,0);
		});

	},

	buildSliders:function(){
		var self=this;
		_.each(App.utils.sliders,function(s){
			
			if(s.img!=""){
				var html = '<div class="slide" data-slide="'+s.id+'"><span class="slide_main_txt" data-position="'+s.ptext+'">'+s.txt+'</span><img class="slide_main_img" src="img/figures/'+s.img+'"></div>'
			}else{
				if(s.ptext=="top"){
					var html = '<div class="slide" data-slide="'+s.id+'"><span class="slide_main_txt" data-position="'+s.ptext+'">'+s.txt+'</span>'
				}else{
					var html = '<div class="slide" data-slide="'+s.id+'"><span class="slide_main_txt solo_text" data-position="'+s.ptext+'">'+s.txt+'</span>'
				}
			}

			$(".graph_wrapper[data-graph='"+s.slider+"'] .slide_container").append(html)

		});

		

		_.each($(".graph_wrapper[data-graph='financement'] .slide"),function(el){
			var nSlide = parseInt($(el).attr("data-slide"));
			if(nSlide>self.maxFinancement){self.maxFinancement=nSlide;}
		});

		_.each($(".graph_wrapper[data-graph='europe'] .slide"),function(el){
			var nSlide = parseInt($(el).attr("data-slide"));
			if(nSlide>self.maxEurope){self.maxEurope=nSlide;}
		});

		$(".graph_wrapper[data-graph='financement'] .endslide").attr("data-slide",self.maxFinancement+1);
		$(".graph_wrapper[data-graph='europe'] .endslide").attr("data-slide",self.maxEurope+1);


		for(var i=0;i<=self.maxFinancement+1;i++){
			if(i==0){
				$(".graph_wrapper[data-graph='financement'] .slider_footer_dot_container").append("<div class='slider_dot active_dot' data-dot='"+i+"'></div>")
			}else{
				$(".graph_wrapper[data-graph='financement'] .slider_footer_dot_container").append("<div class='slider_dot' data-dot='"+i+"'></div>")
			}
		}

		for(var i=0;i<=self.maxEurope+1;i++){
			if(i==0){
				$(".graph_wrapper[data-graph='europe'] .slider_footer_dot_container").append("<div class='slider_dot active_dot' data-dot='"+i+"'></div>")
			}else{
				$(".graph_wrapper[data-graph='europe'] .slider_footer_dot_container").append("<div class='slider_dot' data-dot='"+i+"'></div>")
			}
		}

		$(".slider_dot").on("click",function(){
			var parent = $(this).closest(".graph_wrapper").attr("data-graph");
			var nSlide = $(this).attr("data-dot");
			self.jumpToSlide(parent,nSlide);
		});

	},

	nextSlide:function(graph){
		var self = this;
		var currentSlide = $(".graph_wrapper[data-graph='"+graph+"']").find(".display");
		var currentInt = parseInt(currentSlide.attr("data-slide"));
		var nextInt = currentInt+1;
		currentSlide.removeClass("display");
		$(".graph_wrapper[data-graph='"+graph+"'] .slide[data-slide='"+nextInt+"']").addClass("display")
		if(nextInt>0){
			$(".graph_wrapper[data-graph='"+graph+"']").addClass("main_color");
		}
		if(graph=="financement"){
			if(nextInt==self.maxFinancement+1){
				$(".graph_wrapper[data-graph='"+graph+"'] .slider_arrow_next").addClass("reset");
				$(".graph_wrapper[data-graph='"+graph+"'] .next_clic_zone").addClass("reset");
			}else{
				$(".graph_wrapper[data-graph='"+graph+"'] .slider_arrow_next").removeClass("reset");
				$(".graph_wrapper[data-graph='"+graph+"'] .next_clic_zone").removeClass("reset");
			}
		}else{
			if(nextInt==self.maxEurope+1){
				$(".graph_wrapper[data-graph='"+graph+"'] .slider_arrow_next").addClass("reset");
				$(".graph_wrapper[data-graph='"+graph+"'] .next_clic_zone").addClass("reset");
			}else{
				$(".graph_wrapper[data-graph='"+graph+"'] .slider_arrow_next").removeClass("reset");
				$(".graph_wrapper[data-graph='"+graph+"'] .next_clic_zone").removeClass("reset");
			}

		}
		self.updateDot(graph,nextInt);
	},

	prevSlide:function(graph){
		var self = this;
		var currentSlide = $(".graph_wrapper[data-graph='"+graph+"']").find(".display");
		var currentInt = parseInt(currentSlide.attr("data-slide"));
		var nextInt = currentInt-1;
		currentSlide.removeClass("display");
		$(".graph_wrapper[data-graph='"+graph+"'] .slide[data-slide='"+nextInt+"']").addClass("display")
		if(nextInt==0){
			$(".graph_wrapper[data-graph='"+graph+"']").removeClass("main_color");
		}
		self.updateDot(graph,nextInt);	
	},

	updateDot:function(graph,nextInt){

		_.each($(".slider_dot"),function(e){
			var incDot = parseInt($(e).attr("data-dot"));
			if(incDot<=nextInt){
				$(e).addClass("pastDot");
			}else{
				$(e).removeClass("pastDot");
			}

		});

		$(".graph_wrapper[data-graph='"+graph+"'] .slider_dot").removeClass("active_dot");
		$(".graph_wrapper[data-graph='"+graph+"'] .slider_dot[data-dot='"+nextInt+"']").addClass("active_dot");
	},

	jumpToSlide:function(graph,slide){
		var self = this;
		var currentSlide = $(".graph_wrapper[data-graph='"+graph+"']").find(".display");
		currentSlide.removeClass("display");
		$(".graph_wrapper[data-graph='"+graph+"'] .slide[data-slide='"+slide+"']").addClass("display")
		if(slide==0){
			$(".graph_wrapper[data-graph='"+graph+"']").removeClass("main_color");
		}else{
			$(".graph_wrapper[data-graph='"+graph+"']").addClass("main_color");
		}
		self.updateDot(graph,slide);	

	},
});

module.exports = SlidersView; 