var AuteureView = Backbone.View.extend({

    initialize:function(){
        var self = this;
    },

    render:function(){
        this.bindEvents();
        console.log("test")
         if(App.selectedLang==="fr"){
        	$(".livre_trad[data-lang='en']").css("display","none");
        }else{
        	$(".livre_trad[data-lang='fr']").css("display","none");
        }
    },


    bindEvents:function(){
        var self = this;
    },
});

module.exports = AuteureView;