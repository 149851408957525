var AppRouter = Backbone.Router.extend({

    /* routes: {
        "":"routeFromURL",
        ":anchor":"routeFromURL",
    },

    routeFromURL:function(anchor){ 
        App.anchor = anchor;
        App.updateFromRouter();
    },


    updateRoute:function(triggerize){
        this.navigate( App.anchor, {trigger: triggerize});
    },  */

    routes: {
        "":"routeFromURL",
        ":lang":"routeFromLang",
    },

    routeFromURL:function(params){ 
        App.updateFromRouter();
    },

    routeFromLang:function(lang){
        App.selectedLang = lang;    
        App.updateFromRouter();
    },


    updateRoute:function(triggerize){
        this.navigate( App.selectedLang, {trigger: triggerize});
    },

});

module.exports = AppRouter;