var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var LivreView = require("views/livreView");
        this.livreView = new LivreView();

        var AuteureView = require("views/auteureView");
        this.auteureView = new AuteureView();

        var DataView = require("views/dataView");
        this.dataView = new DataView();

        var SlidersView = require("views/slidersView");
        this.slidersView = new SlidersView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("router");
        this.router = new Router();


        if(location.origin==="http://thepriceofdemocracy.com"){
            this.selectedLang = "en";
        }else{
            this.selectedLang = "fr";
        }

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        App.utils.gatherData(function(){
            self.render();
        });
    },

    render:function(){
    	this.headerView.render();
        this.footerView.render();
        this.livreView.render();
        this.auteureView.render();
        $(".lang_btn[data-lang="+this.selectedLang+"]").addClass("active")
        this.utils.fillTranslations()
    	this.bindEvents();



        if(App.selectedLang==="fr"){
            $(".couv_home[data-lang='en']").css("display","none");
        }else{
            $(".couv_home[data-lang='fr']").css("display","none");
        }

        window.sr = ScrollReveal();
        sr.reveal(".livre_mookup");
        sr.reveal("[data-page='livre'] .main_citation");
        sr.reveal(".livre_main_img");
        sr.reveal(".livre_graph_img");
        if(App.anchor != null){
            setTimeout(function(){
                $('html, body').stop().animate({scrollTop: $("#"+App.anchor).offset().top-100}, 1000 );
            },500)
        }
        
    },


    bindEvents:function(){
    	var self = this;
    },

};

module.exports = App;
window.App = App;