var HeaderView = Backbone.View.extend({

    initialize:function(){
        var self = this;

    },

    render:function(){
        if(App.selectedLang==="fr"){
            $(".header_logo[data-lang='en']").css("display","none");
            
        }else{
            $(".header_logo[data-lang='fr']").css("display","none");
            
        }
        this.bindEvents();
    },

    bindEvents:function(){
        var self = this;
        
        $('#mobile_header_btn').click(function(){
  			$(this).toggleClass('active');
  			$("#header_links_container").toggleClass("open");
		});

         $(".fake_link").on("click", function(){
            var dataPage = $(this).attr("data-link");
            if(dataPage==="actualites"){
                location.href = dataPage;
            }else{
                location.href = dataPage+".php#"+App.selectedLang;    
            }
            
        });

        $(document).on("scroll", function(){
            if(window.innerWidth>639){
                if($(document).scrollTop() > 200){
                  $("#Header").addClass("shrink");
                }else{
                    $("#Header").removeClass("shrink");
                }
            }
        });

        $(".lang_btn").on("click",function(){
            App.selectedLang = $(this).attr("data-lang");
            App.router.updateRoute();
            location.reload()
        });
    },

});

module.exports = HeaderView;