var FooterView = Backbone.View.extend({

    initialize:function(){
        var self = this;
    },

    render:function(){
    	console.log("footer events")
        if(App.selectedLang==="fr"){
        	$(".btn_command[data-lang='en']").css("display","none");
        }else{
        	$(".btn_command[data-lang='fr']").css("display","none");
        }
    },


    bindEvents:function(){
        var self = this;

    },
});

module.exports = FooterView;